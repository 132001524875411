@import 'font-face';

$primary: #3155ff;
$font-family: 'Roboto', 'Prompt', sans-serif;
$font-size-base: 16px;
$font-size-sm: 14px;
$border-color: #e0e0e0;

:root {
  --fc-small-font-size: 14px;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;

  --fc-button-text-color: #505050;
  --fc-button-bg-color: #fff;
  --fc-button-border-color: #d0d4da;
  --fc-button-hover-bg-color: #f0f2fa;
  --fc-button-hover-border-color: #d0d4da;
  --fc-button-active-bg-color: #f0f2fa;
  --fc-button-active-border-color: #d0d4da;

  --fc-event-bg-color: #3155ff;
  --fc-event-border-color: #3155ff;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: red;
}
